<template>
    <div class="buah_kita_page_mobile">
        <div class="sticky">
            <Header></Header>
        </div>
        <div class="main_img_list">
            <img src="../../src/assets/buahKita/1.png" alt=""/>
            <img src="../../src/assets/buahKita/2.png" alt=""/>
            <img src="../../src/assets/buahKita/3.png" alt=""/>
            <img src="../../src/assets/buahKita/4.png" alt=""/>
            <img src="../../src/assets/buahKita/5.png" alt=""/>
            <img src="../../src/assets/buahKita/6.png" alt=""/>
        </div>
        <Contact></Contact>
        <Footer></Footer>
    </div>
</template>

<script>
import Header from "@/components/mobile/Header.vue";
import Contact from "@/components/mobile/Contact.vue";
import Footer from "@/components/mobile/Footer.vue";
export default {
    components: {
        Header,
        Contact,
        Footer,
    },
    data() {
        return {

        };
    },
};
</script>

<style lang="scss" scoped>
.buah_kita_page_mobile {
    .sticky {
        background: #FFF;
        position: sticky;
        top: 0;
        z-index: 9;
    }
    .main_img_list {
        width: 100%;
        img {
            width: 100%;
        }
    }
}
</style>
